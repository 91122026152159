import $ from 'jquery';

import { NavLoad } from 'App/nav/load';
import { TrackLoad } from 'App/track/load';
import { expandCollapse } from 'App/expand-collapse/expand-collapse';
import { VideoPlayer } from 'App/video/video.player';
import { AjaxReplace } from 'App/ajaxreplace/ajaxreplace';
import { Cookie } from 'App/cookie/cookie';
import { selectLink } from 'App/select-link/select-link';
import { resources } from 'App/resources/resources';
import { Validate as formValidate } from 'App/form/validate';
import { modal } from 'App/modal/modal';
import { videoGallery } from 'App/video/gallery';
import * as autoexpand from 'App/expand-collapse/autoexpand';
import * as autocollapse from 'App/expand-collapse/autocollapse';
import { tooltip } from 'App/tooltip/tooltip';
import { Clipboard } from 'App/clipboard/clipboard';
import { stickyHeroNav } from 'App/sticky-hero-nav/sticky-hero-nav';

import { publish } from 'Util/pubsub';
import { throttle } from 'Util/throttle';
import 'magnificPopup';

var $window = $(window);

TrackLoad.init();
NavLoad.init();
AjaxReplace.init();
Cookie.init();
selectLink.init();
videoGallery.init();
tooltip.init();
Clipboard.init();

// Publish Resize event
var publishResizeEvent = function () {
	publish('/window/resize');
};

var publishScrollEvent = function () {
	publish('/window/scroll');
};

// ExpandCollapse
expandCollapse.init();

// These modules have to be initialised after expandCollapse
autoexpand.init();
autocollapse.init();

// Resources
resources.init();

// Form Validation
formValidate.init();

// Video Macros
VideoPlayer.init();

// Umbraco Forms
// init function will load required dependencies if an umbraco form is present
var $umbracoForms = $('.js-umbraco-form');
if ($umbracoForms.length) {
	import(/* webpackChunkName: "UmbracoForms" */ 'App/form/form.umbraco').then(({ UmbracoForms }) => {
		UmbracoForms.init();
	});
}

// Maps
var $maps = $('.js-map');
if ($maps.length) {
	import(/* webpackChunkName: "MapLoad" */ 'App/map/map.load').then(({ MapLoad }) => {
		MapLoad.init($maps);
	});
}

// Rotators
var $rotators = $('.js-rotator');
if ($rotators.length) {
	import(/* webpackChunkName: "Rotator" */ 'App/rotator/rotator').then(({ Rotator }) => {
		Rotator.init($rotators);
	});
}

// Filters
if (document.querySelector('.js-filter')) {
	import(/* webpackChunkName: "Filter" */ 'App/filter/filter').then((filter) => {
		filter.init();
	});
}

// Modal
modal.init({
	onShow: function ($modal) {
		var $rotators = $modal.find('.js-rotator');
		var thisFlick;
		$rotators.each(function (i, el) {
			thisFlick = $(el).data('flickity-instance');

			if (thisFlick) {
				thisFlick.resize();
			}
		});
	}
});

// Back to top
if ($('.js-back-to-top').length) {
	import(/* webpackChunkName: "BackToTop" */ 'App/back-to-top/back-to-top').then(({ BackToTop }) => {
		BackToTop.init();
	});
}

//Sticky hero nav
if ($('.js-hero__sticky-subnav').length) {
	import(/* webpackChunkName: "BackToTop" */ 'App/sticky-hero-nav/sticky-hero-nav').then(({ stickyHeroNav }) => {
		stickyHeroNav.init();
	});
}

$window.on('resize', throttle(publishResizeEvent, 250));
$window.on('scroll', publishScrollEvent);

var $datepickers = $('.js-datepicker');
if ($datepickers.length) {
	$datepickers.each(function () {
		var $datepicker = $(this);

		if (window.matchMedia('(pointer: coarse)').matches) {
			$datepicker.attr('readonly', 'readonly');
		}

		import(/* webpackChunkName: "Pikaday" */ 'pikaday').then(({ default: Pikaday }) => {
			new Pikaday({ field: this, yearRange: 10 });
		});
	});
}

// Single and Multi Image Lightbox
$('.js-lightbox-single').magnificPopup({
	delegate: '.js-lightbox-image',
	type: 'image'
});

$('.js-lightbox-gallery').each(function() {
	$(this).magnificPopup({
		delegate: '.js-lightbox-image',
		type: 'image',
		gallery: {
			enabled: true,
			navigateByImgClick: true,
			preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
		}
	});
});

$('.js-video-group__item').magnificPopup({

	type: 'iframe'

});

/////////////////
// Page/Detail //
/////////////////
import { VideoLoad } from 'App/video/load';
import { Calendar } from 'App/event/calendar';

// Video Macros
VideoLoad.init();

// Check DOM for elements requiring JS
var $tables = $('.c-rte').find('table');

// Table Scroll
if ($tables.length) {
	import(/* webpackChunkName: "TableScroll" */ 'tablescroll').then(() => {
		$tables.tableScroll();
	});
}

// Event calendar add
Calendar.init();

///////////////
// Page/Form //
///////////////
import { FormDelegate } from 'App/form/delegate';

FormDelegate.init();


/*====----   Teraform   ----====*/

if ($('.js-join-form').length) {
	import(/* webpackChunkName: "join-forms" */ 'App/join-forms/join-forms').then(({ JoinForms }) => {
		JoinForms.init();
	});
}